import * as React from "react"
import HorseButton from "../../../components/horse-button"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import TheHorseYouKnow from "../../../images/the-honse-you-no.png"
import "../../../styles/the-horse-you-know.scss"

const Horse2 = () => (
  <Layout bodyClass="the-horse-you-know">
    <Seo title="Horsey Chess" />{" "}
    <img
      src={TheHorseYouKnow}
      alt="The Horse You Know"
      className="thyn-image"
    />
    <h1>WOW REAL HORSE FACT?</h1>
    <h2>
      That one chess piece is actually called the <span>horsey</span>
    </h2>
    <p>Serious chess players really like it when you say that.</p>
    <blockquote>Example: "Horsey to J9" .</blockquote>
    <div className="button-holder">
      <HorseButton />
    </div>
  </Layout>
)

export default Horse2
